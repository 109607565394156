<template>
<div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
        <table-memo-subject :items="items" @editForm="editForm" @refresh="refresh" />
    </div>
    <modal-memo-subject @refresh="refresh" />
    <modal-memo-subject-edit :id="id" @refresh="refresh" />
</div>
</template>
<script>
import ModalMemoSubject from '@/views/component/Modal/ModalMemoSubject/ModalMemoSubject.vue'
import ModalMemoSubjectEdit from '@/views/component/Modal/ModalMemoSubject/ModalMemoSubjectEdit.vue'
import TableMemoSubject from '@/views/component/Table/tableMemoSubject.vue'
export default {
    components: {
        ModalMemoSubject,
        ModalMemoSubjectEdit,
        TableMemoSubject,
    },
    data(){
        return {
            items: [],
            showPreloader: false,
            id: undefined,
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('memoSubjectEdit')
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('memo-subjects')
                .then(res => {
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                }).catch(err => {})
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>