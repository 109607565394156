<template>
    <div>
        <b-modal id="memoSubjectEdit" title="Изменить тему служебной записки" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
            <b-row>
               
                <b-col cols="12">
                    <label for="">Наименование</label>
                    <b-input size="sm" v-model="memoSubject.name" placeholder="Наименование" />
                </b-col>
                <b-col cols="12" class="mt-1">
                    <label for="">Состояние</label>
                    <select v-model="memoSubject.is_active"  class="form-control form-control-sm">
                        <option :value="option.value" v-for="(option, index) in options" :key="index">{{option.name}}</option>
                    </select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editMemoSubject">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    props: ['id'],
    component: {
        ToastificationContent,
    },
    data(){
        return {
            memoSubject: {},
            options:[
                {
                    name: 'Активный',
                    value: 1
                },
                {
                    name: 'Неактивный',
                    value: 0
                }
            ],
            submitButtonDisabled: false,
        }
    },
    methods:{
        editMemoSubject(){
            this.memoSubject.neno_subject_id=this.memoSubject.id
            this.submitButtonDisabled = true
            this.$http
                .patch(`memo-subjects/${this.id.id}`, this.memoSubject)
                .then(res=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    this.$emit('refresh')
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get(`memo-subjects/${this.id.id}/edit`)
                .then(res => {
                    this.memoSubject = res.data
            })
        },
        closeModal(){
            this.$bvModal.hide('memoSubjectEdit')
        }
    }
}
</script>